<template>
  <div>
        <VueSignaturePad
            id="signature"
            width="100%"
            height="500px"
            ref="signaturePad"
            :options="options"
        />
    <b-button v-if="loadingButton" class="btn w-100 btn-primary"  variant="primary" disabled>
      <b-spinner small type="grow"></b-spinner>
      loading...
    </b-button>
      <div class="d-flex justify-content-around gap_1 mt-3" v-else>
        <b-button variant="warning" class="w-25" @click="undo">Undo</b-button>
        <b-button variant="primary" class="w-25" @click="save">Save</b-button>
      </div>
    </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import VueSignature from 'vue-signature-pad'
export default {
  props: ['loadingButton'],
  name: 'App',
  data: () => ({
    options: {
      penColor: '#c0f'
    }
  }),
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VueSignature
  },
  methods: {
    undo () {
      this.$refs.signaturePad.undoSignature()
    },
    save () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (!isEmpty) {
        this.$emit('saveSign', data)
      } else {
        core.showSnackbar('error', 'please sign this Contract First')
      }
    }
  }
}
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
  radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

</style>
